.groupVideo{
    width: 100%;
    height: 100vh;
}
Video{
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
.content{
    font-size: 80px;
    font-family: Josefin Sans;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 12%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 10%;
    color: #ffffff;
}
.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
}