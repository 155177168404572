/* ---------- Container for the Singleorbiting circles animation ---------- */
#circle-Singleorbit-container {
    position: relative;
    top: 30px;
    left: 105px;
    height: 700px;
    width: 300px;
}

/* ---------- Inner Singleorbit - This is the circles closest to the central point ---------- */
/* #inner-Singleorbit {
    position: absolute;
    top: 75px;
    left: 75px;
    width: 150px;
    height: 150px;
    border: 2px #4A437F dashed;
    border-radius: 100%;
    -webkit-animation: spin-right 10s linear infinite;
    animation: spin-right 10s linear infinite;
} */

/* ---------- Repeating styles for the inner Singleorbiting circles ---------- */
/* .inner-Singleorbit-cirlces {
    position: absolute;
    top: 62px;
    left: -6px;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: #9F98E6;
} */



/* ---------- Outer Singleorbit - This is the circles furthest away from the central point ---------- */
#outer-Singleorbit {
    position: absolute;
    top: 0;
    left: 0;
    width: 600px;
    height: 600px;
    border: 2px #4A437F dashed;
    border-radius: 100%;
    -webkit-animation: spin-right 20s linear infinite;
    animation: spin-right 20s linear infinite;
}

/* ---------- Repeating styles for the outer Singleorbiting circles ---------- */
.outer-Singleorbit-cirlces {
   
    position: absolute;
    top: -17px;
    top: 30px;
    left: 125px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    /* background-color: #00FFCA; */
}

.outer-Singleorbit-cirlces1 {
    position: absolute;
    /* top: -17px; */
    top: -5px;
    left: 350px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: #037F26;
}

.outer-Singleorbit-cirlces2 {
    position: absolute;
    /* top: -17px; */
    top: 155px;
    left: 550px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: #4400ff;
}

.outer-Singleorbit-cirlces3 {
    position: absolute;
    /* top: -17px; */
    top: 400px;
    left: 565px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: #0087FF;
}

.outer-Singleorbit-cirlces4 {
    position: absolute;
    /* top: -17px; */
    top: 565px;
    left: 380px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    /* background-color: yellow; */
}


.outer-Singleorbit-cirlces5 {
    position: absolute;
    /* top: -17px; */
    top: 540px;
    left: 135px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    /* background-color: black; */
}

.outer-Singleorbit-cirlces6 {
    position: absolute;
    /* top: -17px; */
    top: 245px;
    left: -10px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    /* background-color: blueviolet; */
}

/* ____________________________________________________ */
#myDiv {
    opacity: 1;
    animation-name: fadeOut;
    animation-duration: 5s;
    /* adjust this value to change the duration of the animation */
}

@keyframes fadeOut {
    from {
        opacity: 0;
    }

    to {
        opacity: 2;
    }
}

/* ____________________________________________________ */


/* ---------- Animation ---------- */
@keyframes spin-right {
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin-left {
    100% {
        -webkit-transform: rotate(-360deg);
        -moz-transform: rotate(-360deg);
        -ms-transform: rotate(-360deg);
        -o-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}