.bg-gray {
  background-color: #f0f2f5;
}
.fs-0 {
  font-size: 4rem;
}
.fs-7 {
  font-size: 0.8rem;
}
/* nav button */
.nav__btn button:hover {
  background: #f0f2f5 !important;
}
.nav__btn button:hover i {
  color: #0d6efd !important;
}
.nav__btn-active i {
  color: #0d6efd !important;
}
.nav__btn-active {
  position: relative;
}
.nav__btn-active:before {
  content: "";
  position: absolute;
  bottom: -4.5px;
  left: 0;
  transform: translate(0, 4.5px);
  width: 80%;
  height: 3px;
  border-bottom: 3px solid #0d6efd !important;
}
/* dropdown */
.dropdown-item:active {
  background-color: #d5d5d5 !important;
}
/* chat box */
.modal-content:hover .fa-phone-alt {
  color: #0d6efd !important;
}
.modal-content:hover .fa-video {
  color: #0d6efd !important;
}
.modal-content:hover .fa-file-image {
  color: #0d6efd !important;
}
.modal-content:hover .fa-plus-circle {
  color: #0d6efd !important;
}
.modal-content:hover .fa-portrait {
  color: #0d6efd !important;
}
.modal-content:hover .fa-adjust {
  color: #0d6efd !important;
}
.modal-content:hover .fa-thumbs-up {
  color: #0d6efd !important;
}
/* edit */
.edit-button {
  opacity: 0;
}
.edit-button:hover {
  opacity: 1;
}
.edit-heading:hover .edit-button {
  opacity: 1;
}
/* scrollbar */
.scrollbar:hover {
  overflow: auto !important;
}
/* sponsor */
.sponsor-icon {
  opacity: 0;
}
.dropdown-item:hover .sponsor-icon {
  opacity: 1;
}
/* popover */
.pop-avatar {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 15px;
}
.chat-box {
  border: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
/* stories */
.story:hover {
  opacity: 0.8;
}
.pointer {
  cursor: pointer;
}
/* comment */
.post__comment .accordion-button::after {
  margin-left: 0 !important;
  display: none !important;
}
.post__comment .accordion-button:hover {
  background: transparent !important;
  border-color: transparent !important;
}
.post__comment .accordion-button:not(.collapsed) {
  background: transparent !important;
}
.post__comment .accordion-button:not(.collapsed) {
  box-shadow: none !important;
}
@media (min-width: 62em) {
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}


/* ==============Scrollbar================ */



        /* width */
        ::-webkit-scrollbar {
          width: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
          box-shadow: inset 5px 5px 20px lightgray;
          border-radius: 50px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
          background: gray;
          border-radius: 50px;
      }

          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
              background: #aaa;
          }