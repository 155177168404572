
a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

    a, a:hover {
        text-decoration: none !important;
    }

/* .content {
    height: 70vh;
} */

.footer-20192 {
    position: relative;
    color: #000000;
    padding: 10px 0;
    background-color: #F8F8F8;
}

/* footer */

.footer-20192 .dropdown button{
    border: 1px solid #9c9595;
    width: 150px;
    text-align: left;
    padding: 5px 10px !important;
    border-radius: 7px;
}

.footer-20192 .dropdown button span{
    margin-left: 30px;
}

.footer-20192 hr{
    color: #8d8585;
}

.footer-20192 .nav-link {
    font-size: 16px;
    margin: 20px 0;
}

.dropdown {
    padding: 0px 0 50px 0;
}

.inner_footer{
    padding: 60px 0 0 0;
   }


    .footer-20192 .container {
        position: relative;
    }

    .footer-20192 h3 {
        font-size: 16px;
        margin-bottom: 10px;
        margin-top: 0;
        line-height: 1.5;
    }

    .footer-20192 .nav-link {
        margin-bottom: 10px;
        line-height: 1.5;
        display: block;
    }

        .footer-20192 .links li a {
            color: #fff;
        }

            .footer-20192 .links li a:hover {
                color: #fff;
            }

    .footer-20192 .social li {
        display: inline-block;
        position: relative;
    }

        .footer-20192 .social li a {
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: inline-block;
            margin: 0;
            padding: 0;
            /*background-color: #8186d5;*/
            /* border: 1px solid #0072fd; */
            color: #0072fd;
        }

            .footer-20192 .social li a > span {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }

    .footer-20192 .footer-logo {
        color: #006eff;
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: .1rem;
    }

    .footer-20192 .copyright {
        color: #000000;
    }

    .footer-20192 .cta {
        -webkit-box-shadow: -20px -20px 0 0 rgba(52, 58, 64, 0.2);
        box-shadow: -20px -20px 0 0 rgba(52, 58, 64, 0.2);
        padding: 20px;
        background-color: #8186d5;
        top: -150px;
        position: relative;
    }

        .footer-20192 .cta h2, .footer-20192 .cta h3 {
            line-height: 1.5;
        }

        .footer-20192 .cta h3 {
            font-size: 20px;
        }
