.hamza {
  /* background-image: linear-gradient(180deg, rgba(25,137,241,0.45171572046787467) 0%, rgba(255,255,255,0.8746849081429446) 100%), url( '../Career/img/Career.png'); */
  /* background-image: linear-gradient(180deg, rgba(25,137,241,0.45171572046787467) 0%, rgba(255,255,255,0.8746849081429446) 100%), url( 'https://picsum.photos/id/237/200/300'); */

  /*height: 41rem;*/
  text-align: center;
  color: #fff;

}


.container{
  overflow: hidden;
}

#ourclients {
  display: block;
   /* margin-left: auto;
  margin-right: auto;  */
 /* background:#f9f9f9;  */
  padding-bottom:40px;
  height:100px;
}
#ourclients .clients-wrap {
  display: block;
  width: 95%;
  margin: 0 auto;
  overflow: hidden;
}
#ourclients .clients-wrap ul {
  display: block;
  list-style: none;
  position: relative;
   margin-left: auto;
  margin-right: auto; 
}
#ourclients .clients-wrap ul li {
  display: block;
  float: left;
  position: relative;
  width: 220px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
#ourclients .clients-wrap ul li img {
  vertical-align: middle;
  max-width: 100%;
  width:150px;
  max-height: 100%;
  -webkit-transition: 0 linear left;
  -moz-transition: 0 linear left;
  transition: 0 linear left;
}
#ourclients h3{
border-bottom:2px solid #3399ff;
width:150px;
padding:2px;
}



.aquib {
  width: auto;
  height: 100px;
  animation: scroll 10s linear infinite;
}

.slide-track {
  width: 100%;
  display: flex;
  gap: 3em;
  overflow: hidden;
}


@keyframes scroll {
  0% {
      transform: translateX(0);
  }

  100% {
      transform: translateX(calc(-150px * 4));
  }
}