/* #carouselExampleIndicators1 .mobile {
    position: absolute;
    z-index: 1;
    margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%;
    max-width: 550px;
}

#carouselExampleIndicators1 .carousel-inner {
    position: absolute;
    margin-left: 4.5%;
    margin-right: 4.5%;
    width: 95%;
    height: 550px;
    border-radius: 30px;
}

    #carouselExampleIndicators1 .carousel-inner img {
        width: 100%;
        height: 100%;
    }

.carousel {
    position: sticky;
}

.btn:not(:disabled):not(.disabled) {
    margin-bottom: 35px !important;
} */


/* ===================================================================== */

/* .FrameS .mobile {
    position: absolute;
    z-index: 1;
    margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%;
    max-width: 550px;
} */

.mobile {
    position: absolute;
    z-index: 1;
    width: 95%;
    /* margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%; */
    max-width: 550px;
}

.InnerSlider .carousel-inner {
    /* position: absolute;
    margin-left: 4.5%;
    margin-right: 4.5%; */
    width: 100%;
    height: 550px;
    border-radius: 30px;
}

@media only screen and (max-width: 430px) {
    .mobile {
        position: absolute;
        z-index: 1;
        width: 80%;
        height: 550px;
        max-width: 650px;
    }


    .InnerSlider .carousel-inner {

        width: 90%;
        height: 550px;
        border-radius: 30px;
        margin-left: 6px;
    }


    .InnerSlider {
        margin-left: 25px;
    }

}


@media only screen and (min-width: 768px) {

    .mobile {
        position: absolute;
        z-index: 1;
        width: 78%;
        height: 255px;
        max-width: 650px;
    }


    .InnerSlider .carousel-inner {

        width: 90%;
        height: 450px;
        border-radius: 10px;
    }

}

/* 
    .InnerSlider{
        margin-left: 25px;
    } */

@media only screen and (min-width: 992px) {

    .mobile {
        position: absolute;
        z-index: 1;
        width: 78%;
        height: 355px;
        max-width: 650px;
    }


    .InnerSlider .carousel-inner {

        width: 90%;
        height: 450px;
        border-radius: 10px;
    }

}

@media only screen and (min-width: 1200px) {


    .mobile {
        position: absolute;
        z-index: 1;
        width: 93%;
        height: 552px;
        /* margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%; */
        max-width: 550px;
    }

    .InnerSlider .carousel-inner {
        /* position: absolute;
    margin-left: 4.5%;
    margin-right: 4.5%; */
    width: 98%;
    height: 550px;
    border-radius: 30px;
    margin-left: 6px;

    }
}

.carousel-control-prev {
    display: none;
}
.carousel-control-next{

    display: none;
}

.carousel-indicators{
    display: none;
}