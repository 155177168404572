.story-section {
    border-radius: 10px;
    border: 2px solid black;
}

.story-img-section {

    width: 100px;
    height: 110px;
    padding: 1px;
}

.story-img {
    border-radius: 50%;
    border: 3px solid #182be8;
}

.story-name {
    font-size: 13px;
    font-weight: 500;
}

.story-title {
    font-size: 12px;
    font-weight: 400
}

.story-btn {

    font-weight: 500;
    text-decoration: none;
}

/* =========================================Story End========================================== */

.swiper-button-next:after, .swiper-button-prev:after{
    font-size: 13px !important;
    background-color: #0d6efd;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 50%;
    color: white;
    font-weight: 700;
}

.mySwiper1{
    padding-bottom: 20px;
}

.swiper-pagination-bullet{

    width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,16px)) !important;
    height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,5px)) !important;
    display: inline-block;
    border-radius: 25% !important;


}
