#info-block section {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    border-radius: 10px;
}

.file-marker>div {
    padding: 0 3px;
    height: auto;
    margin-top: -0.8em;

}

.box-title {
    background: white none repeat scroll 0 0;
    display: inline-block;
    padding: 0 2px;
    margin-left: 1em;
    font-size: 14px;

}


/* ================================= */


/* .profilelist{
    background-color: red;
} */

.profilelist li :hover {

    background-color: #e5e8ec;
}


.usercard {
    background-image: url(../img/usercardbg.png);
    background-repeat: no-repeat;
}

.pointscard {
    background-image: url(../img/pointscard.png);
    background-repeat: no-repeat;
    background-size: contain, cover;

}

.qrcode {
    background-image: url(../img/qrcodebg.jpeg);
    background-repeat: no-repeat;
    background-size: contain, cover;

}


/* ==============Progress Bar=============== */

.indexedStep {
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.indexedStep.accomplished {
    background-color: rgba(0, 116, 217, 1);
}
