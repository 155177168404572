.container {
    width: 100% !important;

    padding-right: 15px !important;

    padding-left: 15px !important;

    margin-right: auto !important;

    margin-left: auto !important;

    overflow: hidden !important;
}

/* .container {
} */

@media (min-width: 576px) {
    .container {
        max-width: 540px !important;

    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px !important;

    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px !important;

    }
}

@media (min-width: 1200px) {

    .container {
        max-width: 1140px !important;

    }
}

/*============ Home===================*/

.HomeBag {
    background-size: cover;
    background-repeat: no-repeat;
}



/*============Button===================*/

.btn-created {
    flex: 1 1 auto;
    /*margin: 10px;*/
    /*padding: 30px;*/
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
    box-shadow: 0 0 20px #eee;
    border-radius: 50px;
}

.btn-1:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: white;
}

.btn-1 {
    background-image: linear-gradient(to right, #000 0%, #3f74e8 51%, #000 100%);
}


.dot {
    overflow: hidden;
    text-overflow: ellipsis;
}


/* -----------------Google translate---------------- */
/* .goog-te-banner-frame {
    display: none !important
} */
.VIpgJd-ZVi9od-ORHb-OEVmcd,
.VIpgJd-ZVi9od-l4eHX-hSRGPd {
    border: none;
    display: none !important
}

.goog-te-gadget {
    border: none;
    color: transparent !important;
}

.goog-te-gadget .goog-te-combo {
    border: none;
    background-color: transparent;
    /* padding-top: -15px; */
}

.goog-te-combo,
a:active {
    border: none;
    text-align: left;
    padding-left: 10px;
    /* width: 80px; */
    -webkit-appearance: none;

}

.google_translate_element {
    margin: 0 !important;
    padding: 0 !important;
}

.VIpgJd-yAWNEb-L7lbkb {
    display: none !important;
}

/* .translated-ltr{
    display: none !important;
} */

/* .goog-te-banner-frame.skiptranslate {
    display: none !important;
}

body {
    top: 0px !important;
} */

.goog-te-combo::after {
    border-style: none !important;
    border: 0 !important;
}

.text-yellow {
    background: linear-gradient(to right, #0084ff 1%, #000000bd 20%, #FFD302 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 3px;
}

.bannerBtn {
    background: rgb(0, 135, 255);
    background: linear-gradient(90deg, rgba(0, 135, 255, 1) 3%, rgba(211, 197, 233, 1) 57%);
}

.bg-Gradiant {
    /* height: 400px; */

    background: linear-gradient(90deg, #D3C6E8 1%, #fff 100%);
}

.bg-Gradiant-2 {
    background: linear-gradient(90deg, #fff 1%, #D3C6E8 100%);
}