
        .aquib {
            width: auto;
            height: 100px;
            animation: scroll 10s linear infinite;
        }

        .slide-track {
            width: 100%;
            display: flex;
            gap: 3em;
            overflow: hidden;
        }


        @keyframes scroll {
            0% {
                transform: translateX(0);
            }

            100% {
                transform: translateX(calc(-150px * 4));
            }
        }


        .textsmall {
            /*background: linear-gradient(to right, #000 1%, #0065fdc5 100%);*/
            background: linear-gradient(to right, #000 1%, #0065fdc5 21%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding-bottom: 2px;
        }
        
        .text {
            background: linear-gradient(to right, #000 1%, #0065fdc5 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding-bottom: 3px;
        }
        