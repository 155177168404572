.Nav-bg{
    background: #F8F8F8;
}

.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link{
    color: rgb(0, 98, 255);;
}
.nav-link{
    color: #03194B;
}
.homeicon {
    font-size: 25px;
    margin-top: -3px;
    color: rgb(0, 98, 255);
}

.footericon {
    color: white;
    text-align: center;
    border: solid 1px white;
    text-decoration: none;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 50%;
    display: inline-block;
    font-size: 16px;
}