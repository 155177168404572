#carouselExampleIndicators1 .mobile {
    position: absolute;
    z-index: 1;
    margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%;
    max-width: 550px;
}

#carouselExampleIndicators1 .carousel-inner {
    position: absolute;
    /*margin-top: 11.5%;*/
    margin-left: 4.5%;
    margin-right: 4.5%;
    width: 95%;
    height: 550px;
    border-radius: 30px;
}

    #carouselExampleIndicators1 .carousel-inner img {
        width: 100%;
        height: 100%;
    }

#carouselExampleIndicators .mobile {
    position: absolute;
    z-index: 1;
    margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%;
    max-width: 550px;
}

#carouselExampleIndicators .carousel-inner {
    /*position: absolute;*/
    /*margin-top: 11.5%;*/
    margin-left: 4.5%;
    margin-right: 4.5%;
    width: 95%;
    height: 90%;
    border-radius: 30px;
}


    #carouselExampleIndicators .carousel-inner img {
        width: 100%;
        height: 100%;
    }

.carousel {
    position: sticky;
}

@media only screen and (max-width: 615px) {
    .slider2 {
        transform: none !important;
    }
}


/*========== 2nd section slider ============*/
.card {
    margin: 0 0.5em;
    box-shadow: none;
    border: none;
    border-radius: 20px;

}
/* .cards-wrapper {
    display: flex;
    justify-content: center;
}


.carousel-control-prev,
.carousel-control-next {
    background-color: #007bff;
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    top: 30%;
    transform: translateY(-50%);
}

.card-body {
    transform: translateY(-60%)
} */