/* ---Sidebar css */



  .sidebar{
      width: 350px;
      min-height: 100vh;
      box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
      background-color: #fff;
      position: fixed;
      top: 0;
      left: -100%;
      z-index: 1;
      transition: 0.5s;
  }
  .sidebar1{
    width: 350px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    /* position: fixed; */
    top: 0;
    left: -100%;
    z-index: 1;
    transition: 0.5s;
}
  .sidebar.active{
      left: 0;
  }
  .sd-header{
     margin-top: 70px;
       display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
  }
  .sd-header1{
    margin-top: 70px;
      display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 15px;
 }
 /* @media only screen and(max-width:1440) {
    .sdheader1 {
        margin-top: 70px;
    }

 } */
  .toggles{
    margin-top: 70px;
    display: none;
  }

  .toggles-close{
    display: none;
  }

  @media only screen and (max-width: 600px) {
    .sd-header {
        margin-top: 100px;
    }
    .sd-header1 {
        margin-top: 0px !important;
    }
    .toggles{
        margin-top: 100px;
        display:inline-table;
    }
    .sidebar{
        width: 320px;
    }
    .sidebar1{
        width: 295px;
    }
    .toggles-close{
        display:inline-table;
    }

  }
  /* @media only screen and (max-width: 300px) {
    .sd-header1 {
        margin-top: 100px;
        margin-top:70px ;
    }
    .toggles{
        margin-top: 100px;
        display:inline-table;
    }
    .sidebar{
        width: 320px;
    }
    .toggles-close{
        display:inline-table;
    }

  } */



  .sd-body{
      padding: 15px;
      max-height: calc(100vh - 150px);
      overflow-x: hidden;
      /* margin-bottom: 150px; */

  }
  .sd-body ul{
      display: inline-block;
      width: 100%;
      margin-bottom: 0;
      padding: 0;
  }
  .sd-body ul li{
      list-style: none;
      margin-bottom: 8px;
  }
  .sd-body ul li .sd-link{
      display: inline-block;
      width: 100%;
      /* padding: 10px 16px; */
      color: #475f7b;
      background-color: #e5e8ec;
      border-radius: 4px;
      cursor: pointer;
      text-decoration: none;
  }