/* .my_menu{
    padding-top: 110px;
} */

.book_container{
    width: 1100px !important;
}

.every_one_logo{
    width: 210px;
    margin-left: 15px;
}
.btn_outline_grey{
    border:1px solid #b5abab;
}

.btn_outline_grey:hover{
    color: #000 !important;
}


.everyone{
    text-align: justify;
    font-weight: bold;
    font-size: 36px;
    font-family: "Josefin Sans";
    margin: -11px -4px;
}

.aaabb{
    padding-bottom: 30px;
}

.discovery{
    transform: rotate(270deg); 
    margin-top: -214px;
    margin-right: -160px;
    float: right; 
}

.text_o{
    font-size: 27px;
}

.display_book{
    bottom: 0%;
}

.store_btn{
    width: 150px;
    height: 45px;
}

.mt_container{
    margin-top: 100px;
}

.bk_right_section p{
    padding-top: 40px;
    font-family: "Josefin Sans";
    text-align: justify;
    font-size: 18px !important;
    font-weight: lighter;
    letter-spacing: 3px;
    margin-left: 15px;
}

.bk_right_section .carousel-item img{
    width: 350px;
    margin-left: 15px;
}


.bk_right_section .txt_primary{
    color: #3d9ef3;
}

.bk_right_section .text{
    font-family: "Rajdhani";
    font-size: 21px;
    font-weight: initial;
}

.book_bind{
    box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.75) !important;
    width: 82px;
 }

.stories_img{
    margin-top: -30px;
    z-index: 1;
    width: 28%;
    position: absolute;
    right: 225px;
}

#scan_section input {  width: 926px;
font-size: 24px;
height: 75px;
color: #434343;
border-radius: 0 20px 20px 0px;
}

#scan_section .input-group .btn {
    position: relative;
    z-index: 2;
    border: 1px solid #ccc;
}

#scan_section .btn_get_app_container{
    top:0;
    right:0;
}

#scan_section .btn_get_app{
    height: 75px;
    width: 200px;
    font-size: 24px;
    border-radius: 15px;
    
}



#scan_now_form { 
	width:900px;
	height:80px;
	position:absolute;
}
#scan_now_form .form_bg { 
	box-shadow:1px 1px 1px rgba(0, 0, 0, 0.25);
	background-color:rgba(255, 255, 255, 1);
	width:900px;
	height:80px;
	position:absolute;
	left:0px;
	top:0px;
	border-top-left-radius:15px;
	border-top-right-radius:15px;
	border-bottom-left-radius:15px;
	border-bottom-right-radius:15px;
}
#scan_now_form .country_code { 
	color:rgba(51.00000075995922, 51.00000075995922, 51.00000075995922, 1);
	width:33px;
	height:34px;
	position:absolute;
	left:71px;
	top:23px;
	font-family:Poppins;
	text-align:center;
	font-size:20px;
	letter-spacing:-1;
}
#scan_now_form .pipeline { 
	transform: rotate(0.000002504476640599167deg);
	background-color:rgba(0, 0, 0, 0.6600000262260437);
	position:absolute;
	left:121px;
	width: 0px;
    height: 30px;
	top:25px;
}
#scan_now_form .pipeline { 
	border:1px solid rgba(51.00000075995922, 51.00000075995922, 51.00000075995922, 1);
}
#scan_now_form .input_form { 
	width: 501px;
    height: 80px;
    margin-left: 139px;
    position: absolute;
    left: 0px;
    top: 0px;
    font-size: 20px;
    border: none;
    outline-width: 0;
    letter-spacing: -1;
    font-family: Poppins;
    color: rgba(217.0000022649765, 217.0000022649765, 217.0000022649765, 1);
}

#scan_now_form ::placeholder { /* Most modern browsers support this now. */
    color:    #ccc;
 }

#scan_now_form .btn_bg { 
	background-color:rgba(0, 135.00000715255737, 255, 1);
	width:200px;
	height:80px;
	position:absolute;
	left:700px;
	top:0px;
	border-top-left-radius:15px;
	border-top-right-radius:15px;
	border-bottom-left-radius:15px;
	border-bottom-right-radius:15px;
}
#scan_now_form .input_btn { 
	color:rgba(255, 255, 255, 1);
	width:83px;
	height:29px;
	position:absolute;
	left:759px;
	top:23px;
	font-family:Poppins;
	text-align:left;
	font-size:20px;
	letter-spacing:-1;
}
#scan_now_form .country_logo { 
	width:32px;
	height:24px;
	position:absolute;
	left:29px;
	top:22px;
	background-image:url(../assets/img/image_38.png);
	background-repeat:no-repeat;
	background-size:cover;
}
.billions{
    width: 140px;
}
.store_btn img{
    padding: 0 !important;
    width: 160px;
    height: 50px !important;
    margin-right: 5px;
}

.scanner_header h3{
    margin: 100px 0 20px 0;
}


#scan_section .store_btn {
    margin-top: 160px;
}


#get_no #scan_now_form { 
	width:690px;
	height:80px;
    margin-top: 35px;
	position:relative;
}
#get_no .form_bg { 
	box-shadow:1px 1px 1px rgba(0, 0, 0, 0.25);
	background-color:rgba(255, 255, 255, 1);
	width:690px;
	height:80px;
	position:absolute;
	left:0px;
	top:0px;
	border-top-left-radius:15px;
	border-top-right-radius:15px;
	border-bottom-left-radius:15px;
	border-bottom-right-radius:15px;
}
#get_no .country_code { 
	color:rgba(51.00000075995922, 51.00000075995922, 51.00000075995922, 1);
	width:33px;
	height:34px;
	position:absolute;
	left:71px;
	top:23px;
	font-family:Poppins;
	text-align:center;
	font-size:20px;
	letter-spacing:-1;
}
#get_no .pipeline { 
	transform: rotate(0.000002504476640599167deg);
	background-color:rgba(0, 0, 0, 0.6600000262260437);
	position:absolute;
	left:121px;
	width: 0px;
    height: 30px;
	top:25px;
}
#get_no .pipeline { 
	border:1px solid rgba(51.00000075995922, 51.00000075995922, 51.00000075995922, 1);
}
#get_no .input_form { 
	width: 501px;
    height: 80px;
    margin-left: 139px;
    position: absolute;
    left: 0px;
    top: 0px;
    font-size: 20px;
    border: none;
    letter-spacing: -1;
    font-family: Poppins;
    color: rgba(217.0000022649765, 217.0000022649765, 217.0000022649765, 1);
}

#get_no  ::placeholder { /* Most modern browsers support this now. */
    color:    #ccc;
 }
 #get_no .btn_bg { 
    background-color: rgba(0, 135.00000715255737, 255, 1);
    width: 200px;
    border: none;
    margin-top: 35px;
    height: 80px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

#get_no h1{
    font-family: Poppins;
    margin: 20px 0px;
    font-weight: bold;
}

#get_no .context_container{
    margin-left: 50px;
}
 #get_no .input_btn { 
	color:rgba(255, 255, 255, 1);
	width:83px;
	height:29px;
	left:759px;
	top:32px;
	font-family:Poppins;
	text-align:left;
	font-size:20px;
	letter-spacing:-1;
}
#get_no .country_logo { 
	width:32px;
	height:24px;
	position:absolute;
	left:29px;
	top:22px;
	background-image:url(../assets/img/image_38.png);
	background-repeat:no-repeat;
	background-size:cover;
}



@media only screen  and (min-width: 1200px) and (max-width: 1368px)  
{ 
    .layout_container {
        max-width: 1168px  !important;
    }

    #get_no .store_btn_section {
        margin: -20px 80px;
    }

 

    #memories .text_context h2 {
        font-size: 36px !important;
    }

    #memories .text_context p {
        font-size: 20px;
    }

    #memories img {
        width: 35%;
    }

    .navbar-nav .nav-link{
        font-size: 16px;
    }

    #stories h1 {
        font-size: 36px;
    }

    .or-2 {
        width: 100%;
    }

    #stories p {
        font-size: 18px;
    }

    
    .display_book{
        bottom: 0%;
    }


    .stories_img{
        width: 450px;
        margin-top: 0px; 
        z-index: 1;
        position: absolute;
        right: 60px
    }

    .join_us_btn {
        padding: 15px 60px;
    }

    .download_btn_outer_div {
        margin: 20px 80px;
    }

    #join_us .join_us_context .join_us_text h1 {
  margin-top: 40px;
    }

    /* upper form css */
    #get_no #scan_now_form{
        width: 620px;
    }

    #get_no .form_bg{
        width: 620px;
    }
    #get_no .input_form {
        width: 440px;
    }

    /* bottom form css */
    #scan_now_form {
        width: 770px;
    }

    #scan_now_form .input_form {
        width:440px;
    }   
    
    #scan_now_form .form_bg {
        width: 620px;
    }

    #scan_now_form .btn_bg {
        left: 500px;
    }

    #scan_now_form .input_btn {
        left: 550px;
    }
} 


@media only screen  and (min-width: 1368px) and (max-width: 1600px)  
{ 
    .or-2 {
        width: 80%;
    }

    .download_btn_outer_div {
        margin: 10px 130px;
    }

    #get_no .store_btn_section {
        margin: -20px 80px;
    }

    #memories .text_context h2 {
        font-size: 40px;
    }

    #memories img {
        margin-left: 20px;
        width: 30%;
    }

    #memories .text_context {
        padding: 42px 0;
    }

    .stories_img {
        width: 450px;
        margin-top: -36px;
        z-index: 1;
        position: absolute;
        right: 50px;
    }

    #stories p {
        margin: 0px 100px;
    }
}