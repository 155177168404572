

#landingframe .carousel-item img {
    width: 231px !important;
    border-radius: 20px;
}

#landing .second_carousel img{
margin-top: 100px;
}


@media only screen  and (min-width: 1200px) and (max-width: 1368px)  
{ 
    #landing {
        padding: 150px 65px;
    }

    .bottom_navbar {
        padding: 0px 0px;
        width: 100%;
    }

    .bottom_navbar a {
        padding: 14px 9px;
    }
}