

.grabbg {
    background: rgb(187,187,187);
    background: linear-gradient(0deg, rgba(187,187,187,1) 0%, rgba(251,251,255,0.8494748241093313) 97%);
}

.bg {
    background-image: url("../company/img/Company7.png");
    border-radius: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg2 {
    background-image: url("../company/img/Company8.png");
    border-radius: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.bg1 {
    background-image: url("../company/img/Company10.png");
    border-radius: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.bg3 {
    background-image: url("../company/img/Company9.png");
    border-radius: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}