.slider-1{
  overflow: hidden;
  border: 20px;
  width: 237px;
  height: 507px;
}

.slider-1 .carousel{position: sticky;
    width: 237px;
    margin: 0px 0px;
    overflow: hidden;
    border-radius: 30px;
}

.slider-2{
  overflow: hidden;
  border: 20px;
  width: 237px;
  height: 507px;
}

.slider-2 .carousel{position: sticky;
    width: 237px;
    margin: 0px 0px;
    overflow: hidden;
    border-radius: 30px;
}

/*  */
.landing_container{
    max-width: 1300px !important;
}

#landing{
padding: 150px 0;
}

#landing .scanner_div{
  margin: auto 0;
  width: 260px;
}

.scanner_section{
  padding: 10px 40px;
}



#landing .entertainment, #landing .see{
    font-size: 60px;
    font-weight:bolder;
}

.landing_btn{
    margin: 10px 0;
}


.landing_btn img{
width: 125px;
}

p{
    font-family: "Josefin Sans";
    
}

.see_container{
  font-size: 60px;
  font-weight: bold;
}


.see_container .animation {
  vertical-align: sub;
  height: 1em;
  overflow: hidden;
  display: inline-block;
  line-height: 2em;
}

.see_container ul {
animation: animation 10s infinite;
padding: 0;
margin-top: -0.55em;
list-style: none;
}

@keyframes animation {
  0%        { margin-top: -0.55em }
  12.5%     { margin-top: -2.54em }
  25%       { margin-top: -4.55em }
  37.5%     { margin-top: -6.55em }
  50%       { margin-top: -8.5em }
  62.5%     { margin-top: -6.55em }
  75%       { margin-top: -4.55em }
  87.5%     { margin-top: -2.54em }
  100%      { margin-top: -0.55em }
}


.top_p p{
  font-size: 14px;
  color: #a9a4a4;
  margin-bottom: 5px;
  font-weight: lighter;
  font-family: "Josefin Sans";
}

.bottom_p p{
  font-size: 12px;
}

/* Place the bottom_navbar at the bottom of the page, and make it stick */
.bottom_navbar {
  background-color: #fff;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  text-align: center;
  left: 0;
  right: 0;
  padding: 0 180px;
  width: 100%;
  }
  
  /* Style the links inside the navigation bar */
  .bottom_navbar a {
    float: left;
    display: block;
    color: #7f7e7e;
    text-align: center;
    padding: 14px 20px;
    font-family: "Josefin Sans";
    text-decoration: none;
    font-size: 14px;
  }
  
  /* Change the color of links on hover */
  .bottom_navbar a:hover {
    background-color: #ddd;
    color: black;
  }
  
  /* Add a color to the active/current link */
  .bottom_navbar a.active {
    background-color: #04AA6D;
    color: white;
  }

  /* #landing_footer {
    position: fixed;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
  } */

.carousel-item img{
  /* width: 95% !important; */
  width: 340px;
  margin: 7px 7px;
}

.landing_modal .cancel_btn button{
  position: absolute;
  top: -30px;
  bottom: 0px;
  right: 54%;
  border-radius: 50px;
  left: 46%;
  color: #fff;
  text-align: center;
  width: 30px;
  border: 2px solid #ccc;
  height: 30px;

}

.landing_modal .modal-content {
  border-radius: 30px !important;
  padding: 50px 125px;
  position: relative;
  /* display: flex; */
  /* flex-direction: column; */
  /* width: 107%; */
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  /* border-radius: var(--bs-modal-border-radius); */
  outline: 0;
}