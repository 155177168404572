/* ---------- Container for the orbiting circles animation ---------- */
#circle-orbit-container {
    position: relative;
    top: 25px;
    left: 35px;
    height: 300px;
    width: 300px;
}

/* ---------- Inner orbit - This is the circles closest to the central point ---------- */
/* #inner-orbit {
    position: absolute;
    top: 75px;
    left: 75px;
    width: 150px;
    height: 150px;
    border: 2px #4A437F dashed;
    border-radius: 100%;
    -webkit-animation: spin-right 10s linear infinite;
    animation: spin-right 10s linear infinite;
} */

/* ---------- Repeating styles for the inner orbiting circles ---------- */
/* .inner-orbit-cirlces {
    position: absolute;
    top: 62px;
    left: -6px;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: #9F98E6;
} */

/* ---------- Middle orbit - This is the circles second closest to the central point ---------- */
#middle-orbit {
    position: absolute;
    top: 45px;
    left: 45px;
    width: 400px;
    height: 400px;
    border: 2px #4A437F dashed;
    border-radius: 100%;
    -webkit-animation: spin-right 20s linear infinite;
    animation: spin-right 25s linear infinite;
}

/* ---------- Repeating styles for the inner orbiting circles ---------- */
.middle-orbit-cirlces {
    position: absolute;
    top: 85px;
    left: 14px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    /* background-color: #4A437F; */
    /* animation: spin-left 11s linear infinite; */
}

.middle-orbit-cirlces1 {
    position: absolute;
    top: -10px;
    left: 140px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    /* background-color: green; */
}

.middle-orbit-cirlces2 {
    position: absolute;
    top: 20px;
    left: 300px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    /* background-color: lightcoral; */
}

.middle-orbit-cirlces3 {
    position: absolute;
    top: 180px;
    left: 380px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    /* background-color: lightseagreen; */
}

.middle-orbit-cirlces4 {
    position: absolute;
    top: 340px;
    left: 305px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    /* background-color: #4A437F; */
}
.middle-orbit-cirlces5 {
    position: absolute;
    top: 340px;
    left: 305px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    /* background-color: #4A437F; */
}
.middle-orbit-cirlces6 {
    position: absolute;
    top: 260px;
    left: 0px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    /* background-color: #4A437F; */
}
.middle-orbit-cirlces7 {
    position: absolute;
    top: 375px;
    left: 130px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    /* background-color: #4A437F; */
}

/* ---------- Outer orbit - This is the circles furthest away from the central point ---------- */
#outer-orbit {
    position: absolute;
    top: 0;
    left: 0;
    width: 500px;
    height: 500px;
    border: 2px #4A437F dashed;
    border-radius: 100%;
    -webkit-animation: spin-right 20s linear infinite;
    animation: spin-right 20s linear infinite;
}

/* ---------- Repeating styles for the outer orbiting circles ---------- */
.outer-orbit-cirlces {
    position: absolute;
    /* top: -17px; */
    top: 10px;
    left: 125px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    /* background-color: #00FFCA; */
}

.outer-orbit-cirlces1 {
    position: absolute;
    /* top: -17px; */
    top: -3px;
    left: 300px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: #037F26;
}

.outer-orbit-cirlces2 {
    position: absolute;
    /* top: -17px; */
    top: 95px;
    left: 440px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: #4400ff;
}

.outer-orbit-cirlces3 {
    
    position: absolute;
    /* top: -17px; */
    top: 436px;
    left: 390px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: rgb(38, 34, 255);
}

.outer-orbit-cirlces4 {
    position: absolute;
    /* top: -17px; */
    top: 280px;
    left: 480px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    /* background-color: #0087FF; */
}


.outer-orbit-cirlces5 {
    position: absolute;
    /* top: -17px; */
    top: 482px;
    left: 230px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    /* background-color: black; */
}

.outer-orbit-cirlces6 {
    position: absolute;
    /* top: -17px; */
    top: 130px;
    left: 12px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    /* background-color: blueviolet; */
}
.outer-orbit-cirlces7 {
    position: absolute;
    /* top: -17px; */
    top: 130px;
    left: 12px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    /* background-color: blueviolet; */
}
.outer-orbit-cirlces8 {
    position: absolute;
    /* top: -17px; */
    top: 420px;
    left: 70px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    /* background-color: blueviolet; */
}
.outer-orbit-cirlces9 {
    position: absolute;
    /* top: -17px; */
    top: 280px;
    left: -8px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: #efef00;

    /* background-color: blueviolet; */
}

/* ____________________________________________________ */
#myDiv {
    opacity: 1;
    animation-name: fadeOut;
    animation-duration: 5s;
    /* adjust this value to change the duration of the animation */
}

@keyframes fadeOut {
    from {
        opacity: 0;
    }

    to {
        opacity: 2;
    }
}

/* ____________________________________________________ */


/* ---------- Animation ---------- */
@keyframes spin-right {
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin-left {
    100% {
        -webkit-transform: rotate(-360deg);
        -moz-transform: rotate(-360deg);
        -ms-transform: rotate(-360deg);
        -o-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}