a {
    text-decoration: none !important;
}

/* ==============Text Animation================= */
/* body {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #34495e;
} */
.content {
    /* 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 160px;
    overflow: hidden;
    font-family: 'Lato', sans-serif;
    color: #ecf0f1; */
    position: absolute;
    font-size: 50px;
    line-height: 40px;
}

.content__container {
    /* font-weight: 600; */
    overflow: hidden;
    height: 50px;
    /* padding: 0 50px; */
}

.content__container__text {
    display: inline;
    float: left;
    margin: 0;
    font-size: 35px;
    margin-top: 5px;
    font-weight: 500;

}

.content__container__list {
    /* font-weight: 350; */
    margin-top: 0;
    padding-left: 310px;
    font-size: 20px;
    text-align: left;
    list-style: none;
    -webkit-animation-name: change;
    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-name: change;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}

.content__container__list__item {
    line-height: 50px;
    margin: 0;
}

@-webkit-keyframes opacity {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@-webkit-keyframes change {

    0%,
    12.66%,
    100% {
        transform: translate3d(0, 0, 0);
    }

    16.66%,
    29.32% {
        transform: translate3d(0, -25%, 0);
    }

    33.32%,
    45.98% {
        transform: translate3d(0, -50%, 0);
    }

    49.98%,
    62.64% {
        transform: translate3d(0, -75%, 0);
    }

    66.64%,
    79.3% {
        transform: translate3d(0, -50%, 0);
    }

    83.3%,
    95.96% {
        transform: translate3d(0, -25%, 0);
    }
}

@-o-keyframes opacity {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@-o-keyframes change {

    0%,
    12.66%,
    100% {
        transform: translate3d(0, 0, 0);
    }

    16.66%,
    29.32% {
        transform: translate3d(0, -25%, 0);
    }

    33.32%,
    45.98% {
        transform: translate3d(0, -50%, 0);
    }

    49.98%,
    62.64% {
        transform: translate3d(0, -75%, 0);
    }

    66.64%,
    79.3% {
        transform: translate3d(0, -50%, 0);
    }

    83.3%,
    95.96% {
        transform: translate3d(0, -25%, 0);
    }
}

@-moz-keyframes opacity {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@-moz-keyframes change {

    0%,
    12.66%,
    100% {
        transform: translate3d(0, 0, 0);
    }

    16.66%,
    29.32% {
        transform: translate3d(0, -25%, 0);
    }

    33.32%,
    45.98% {
        transform: translate3d(0, -50%, 0);
    }

    49.98%,
    62.64% {
        transform: translate3d(0, -75%, 0);
    }

    66.64%,
    79.3% {
        transform: translate3d(0, -50%, 0);
    }

    83.3%,
    95.96% {
        transform: translate3d(0, -25%, 0);
    }
}

@keyframes opacity {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes change {

    0%,
    12.66%,
    100% {
        transform: translate3d(0, 0, 0);
    }

    16.66%,
    29.32% {
        transform: translate3d(0, -25%, 0);
    }

    33.32%,
    45.98% {
        transform: translate3d(0, -50%, 0);
    }

    49.98%,
    62.64% {
        transform: translate3d(0, -75%, 0);
    }

    66.64%,
    79.3% {
        transform: translate3d(0, -50%, 0);
    }

    83.3%,
    95.96% {
        transform: translate3d(0, -25%, 0);
    }
}

/* ==============Text Animation================= */


.form-group {
    margin-bottom: 1rem;

}


.or {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #d7d7d7;
    line-height: 0.1em;
    margin: 30px 0 20px;
}

.or span {
    background: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
    padding: 0 10px;
}

.or-2 {

    width: 60%;
    text-align: center;
    line-height: 0.1em;
    margin: 30px 0 20px;
}

.or-2 span {
    text-align: center;
    color: #fff;
    padding: 0 10px;
}

.or-3 {
    width: 100%;
    border-top: 2px solid #fff;
}

.newFrame {
    top: 50%;
    left: 50%;
    transform: translate(-14%, -12%);
    /* overflow: hidden; */

}

.twoMobileFrame {
    top: 50%;
    left: 50%;
    transform: translate(5%, 80%);
    /* overflow: hidden; */

}

#scanNowModal .cancel_btn{
    width: 20px;
    border: 2px solid #fff;
    top: -32px;
    padding: 15px;
    border-radius: 25px;
    left: 49%;
    right: 49%;
    height: 20px;
    text-align: center;
    position: absolute;
    text-align: center;
    /* background: red; */
    color: #fff;
}

.form_btn_playstore img{
    width: 140px;
    height: 45px;
}

.lg_form form .login_btn{
    font-size: 16px;
    background-color: rgba(0, 135.00000715255737, 255, 1);
}
.lg_form form input{
    font-size: 15px;
}
.txt_seconary{
    color: rgba(0, 135.00000715255737, 255, 1);
}


#scanNowModal .btn-close {
    box-sizing: content-box;
    width: 1em;
    position: absolute;
    font-weight: 600;
    font-size: 20px;
    /* height: 1em; */
    margin: -20px -15px;
    color: #fff !important;
    background: none;
    border: 0;
    /* border-radius: 0.375rem; */
    opacity: .5;
}

#scanNowModal .modal-content{
    width: 590px;
    height: 620px;
}

#scanNowModal .modal-body{
    margin: 40px auto;
}

#scanNowModal .img-fluid{
width: 120px;
}

#scanNowModal .modal-lg, #scanNowModal .modal-xl {
    --bs-modal-width: 590px;
}


/* ===================================================== */

.see_ent{
    font-size: 35px;
    margin: 70px 0px 60px 0px;
    font-weight: bold;
    width: 295px;
}

.login_form_input{
    width: 295px;
    height: 40px;
    font-size: 16px;
}

.login_bottom{
    width: 295px;
}

.aaabb img{
    width: 360px;
}

.lg_form{
    margin:30px 65px;
}

.google-login_btn :hover{
color: #fff !important;
}




/* .display_book{
    top: 20%;
    right:0%;
    left: 0%;
    transform: translate(-50%, -50%);
} */

.layout_container{
    max-width: 1500px !important;
}

.nav-link{
    font-size: 18px;
    margin: 10px;
}

#get_no input
{
height: 65px;
}

#get_no p{
    font-size: 24px;
    line-height: 25px;
    padding: 0;
    margin: 0;
}

#get_no .store_btn img{
    width: 150px;
}

#get_no .store_btn_section{
    margin: -20px 30px;
}

.parent_section{
    height: 540px;
}

#get_no .one_section {
height: 380px;
margin:90px 0;
}

.two_section{
    height: 480px;
    margin: 30px 0;
}

/* ============================ */

.join_us_banner{
    height: 280px;
}
#join_us .join_us_context .join_us_text h1{
    font-size: 48px;
}
#join_us .join_us_context .join_us_text h1 .dash{
    font-size: 36px;
}

.join_us_btn button{
    text-align: center;
    padding-left: 100px;
    padding-right: 100px;
    margin-top: 3.4rem;
}

.download_btn_outer_div{
        margin: 10px 150px;
}

.join_us_btn{
    padding: 15px 90px;
    /* margin-top: 15%; */
    font-weight: bold;
    margin: 75px 25px;
}

.get_the_app {
    right: 0;
    top:0;
    margin-right: -30px;
    margin-top: 80px;
}

#get_no .one_section{
    width: 85%;
}

#stories{
    margin: 40px 0;
}

#stories p{
    font-family: "Josefin Sans";
    margin: 50px 100px;
    text-align: justify;
    font-size: 21px;
}

#stories h1{
    margin: 60px 100px;
    font-size: 48px;
    font-family: "Josefin Sans";
}

.stories_img{
    margin-top: -36px;
    width: 30%;
    z-index: 1;
    position: absolute;
    right: 230px

}

#scan_now{
    margin: 100px 0;
}
#scan_now .form_input{
height: 75px;
}

#scan_now .form_btn .btn{
    height: 75px;
    width: 200px;
    background-color: #0d6efd;
}

#scan_now .form_btn{
    right:0;
}

#scan_now .store_btn{
    width: 150px;
}

#discover{

}

#discover h1{ 
    font-size: 60px;
    font-family: "Josefin Sans";
}

#discover img{ 
   width: 150px;
   height: 4px;
}


#memories{
margin: 120px 0;
}

#memories img{
    margin-left: 20px;
    width: 25%;
}

#memories .text_context{
    padding: 60px 0;
    float: right;
    margin: 0 140px;
    width: 48%;
}

#memories .text_context h2{
 font-size: 48px;
 font-family: "Josefin Sans";
}

#memories .text_context p{
    margin-top: 30px;
    font-size: 24px;
    font-family: 'Josefin Sans';
    text-align: "justify";
   }

 

.join_us_banner{
    width: 80%;
    background-repeat: no-repeat;
}