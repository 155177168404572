.img {
  /* width: 1920px;*/
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../blog/img/blogBg.png");
}

.img1 {
  /* width: 1920px;*/
  background-repeat: no-repeat;
  background-size: cover;

  background-image: url("https://aakaarinfotech.com/download/companyBg.png");
}

.grad {
  background: rgb(49, 156, 255);
  background: linear-gradient(90deg, rgba(49, 156, 255, 1) 0%, rgba(255, 255, 255, 1) 37%);
}

.grad1 {
  background: rgb(25, 137, 241);
  background: linear-gradient(90deg, rgba(25, 137, 241, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.grad2 {
  background: rgb(49, 156, 255);
  background: linear-gradient(280deg, rgba(49, 156, 255, 1) 0%, rgba(255, 255, 255, 1) 37%);
}


#ourclients {
  display: block;
  /* margin-left: auto;
      margin-right: auto;  */
  /* background:#f9f9f9;  */
  padding-bottom: 40px;
  height: 100px;
}

#ourclients .clients-wrap {
  display: block;
  width: 95%;
  margin: 0 auto;
  overflow: hidden;
}

#ourclients .clients-wrap ul {
  display: block;
  list-style: none;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

#ourclients .clients-wrap ul li {
  display: block;
  float: left;
  position: relative;
  width: 220px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

#ourclients .clients-wrap ul li img {
  vertical-align: middle;
  max-width: 100%;
  width: 150px;
  max-height: 100%;
  -webkit-transition: 0 linear left;
  -moz-transition: 0 linear left;
  transition: 0 linear left;
}

#ourclients h3 {
  border-bottom: 2px solid #3399ff;
  width: 150px;
  padding: 2px;
}

/* {
    max-width: 1000px;
   
    background: var(--highlight);
    padding: 30px;
    box-sizing: border-box;
    color: var(--background);
    margin: 20px;
    border-radius: 30px;
} */